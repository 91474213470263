import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Icon from '../icons/Icon'

const LinkButton = props => {
  const classes = cx(`button`, {
    'is-full-width': props.isFullWidth,
    'is-disabled': props.isDisabled
  })
  return (
    <a href={props.linkUrl} title={props.text} className={classes}>
      <div className="button-inner-wrapper">
        {props.iconName && <Icon name={props.iconName} />}
        <span className="text">{props.text}</span>
      </div>
    </a>
  )
}

LinkButton.propTypes = {
  linkUrl: PropTypes.string.isRequired,
  text: PropTypes.string,
  iconName: PropTypes.string,
  isFullWidth: PropTypes.bool,
  isDisabled: PropTypes.bool
}

LinkButton.defaultProps = {
  text: ''
}

export default LinkButton

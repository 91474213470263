import React from "react";
import ReactDOM from "react-dom";
import AccountLogin from "./ui/views/AccountLogin";
import * as serviceWorker from "./serviceWorker";
import "./styles/styles.scss";

const REGISTER_LINK = "https://aka.ms/ssprsetup";
const CHANGE_LINK =
  "https://account.activedirectory.windowsazure.com/ChangePassword.aspx";
const FORGOT_LINK = "https://aka.ms/sspr";

ReactDOM.render(
  <AccountLogin
    linkRegister={REGISTER_LINK}
    linkChange={CHANGE_LINK}
    linkForgot={FORGOT_LINK}
  />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react'
import PropTypes from 'prop-types'
//get all valid values from the IcoMoon list
import IcoMoonData from '../../styles/fonts/icon-font/selection.json'

const Icon = (props) => (
  <span
  	className={ `icon icon-${ props.name }` }
  	aria-hidden='true'
  	onClick={ props.onClick }>
  </span>
)
Icon.propTypes = {
	name: PropTypes.oneOf(IcoMoonData.icons.map(iconObj => iconObj.properties.name)).isRequired,
	onClick: PropTypes.func
}
Icon.defaultProps = {
	onClick: function(){}
}

export default Icon
import React from 'react'
import PropTypes from 'prop-types'
import logo from './image/logo-alantra.png'
import LinkButton from '../buttons/LinkButton'

const AccountLogin = ({ linkRegister, linkChange, linkForgot }) => {
  return (
    <div className="wrapper">
      <div className="account-selector">
        <figure className="logo-figure">
          <img src={logo} alt="Alantra" />
          <figcaption className="subtitle">
            Alantra Network Identity Service Access
          </figcaption>
        </figure>
        <LinkButton
          linkUrl={linkRegister}
          text="Please Register"
          iconName="new_user"
          isDisabled={false}
          isFullWidth={true}
        />
        <LinkButton
          linkUrl={linkChange}
          text="Change your password"
          iconName="key"
          isDisabled={false}
          isFullWidth={true}
        />
        <LinkButton
          linkUrl={linkForgot}
          text="Forgot your password?"
          iconName="key"
          isDisabled={false}
          isFullWidth={true}
        />
      </div>
    </div>
  )
}

AccountLogin.propTypes = {
  linkRegister: PropTypes.string,
  linkChange: PropTypes.string,
  linkForgot: PropTypes.string
}

export default AccountLogin
